.page-header, .container-header{
	background-color: #222;
	color: white;
	width: 100%;
	border-bottom: none;
}
.page-header{
	margin: 0 auto;
	padding: 10px 0px;
}
.nav-icon{
	padding-right: 8px;
}
#navbar-main{
	background-color: #0f6b63;
}
.navbar {
	border-radius: 0px;
	margin-bottom: 0;
	border-bottom: none;
}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
	background-color: #0f6b63;
	color:#82b501;
}
.navbar-inverse .navbar-nav > li > a{
	color: white;
	font-size: 1.2em;
}
.navbar-inverse .navbar-nav > li > a:hover
{
	color:#82b501;
}
.slogan{
	color:white;
}
/*CONTAINERS*/
.margin-top-fix{
	margin-bottom: 20px;
}
.container-slider, .container-slider .row{
	margin-top: 150px;
	z-index: 1000;
	width: 100%;
}
.container-slider .row{
	margin-left: 0;
	margin-right: 0;
}
.panel-body a{
	text-decoration: none;
	color: inherit;
}
.container-1, .container-2, .container-3{
	margin-top: 20px; 
	z-index: 1000;
	border: none;
}
.panel{
	border:none;
}
.panel, .panel-heading{
	color: white;
}
.panel-body{
	border-radius: 0px 0px 10px 10px;
-moz-border-radius: 0px 0px 10px 10px;
-webkit-border-radius: 0px 0px 10px 10px;
}
.panel{
	border-radius: 15px 15px 15px 15px;
-moz-border-radius: 15px 15px 15px 15px;
-webkit-border-radius: 15px 15px 15px 15px;
}
.panel-heading{
	border-radius: 10px 10px 0px 0px;
-moz-border-radius: 10px 10px 0px 0px;
-webkit-border-radius: 10px 10px 0px 0px;
}
.panel-heading h3{
	font-family: 'tahoma', sans-serif;
	margin-top: 7px;
	margin-bottom: 7px;
	color: white;
}
.container-1 .panel .panel-heading,.container-1 .panel .panel-body{
	background: none repeat scroll 0 0 #3498db;
}
.container-2 .panel .panel-heading,.container-2 .panel .panel-body{
	background: none repeat scroll 0 0 #789b1e;
}
.container-3 .panel .panel-heading,.container-3 .panel .panel-body{
	background: none repeat scroll 0 0 #2a2a2a;
}
.container-1 .panel .panel-heading, .container-2 .panel .panel-heading, .container-3 .panel .panel-heading {
	border: none;
	border-bottom: 1px dashed white;
}
/*FROALA*/
.img_right{
	display: inline-block;
	float: right;
}
/*FORMS*/
#footerForm input, #footerForm textarea, #newsletterForm input{
	margin-bottom: 10px;
}
.btn-gironina{
	background-color: #039687;
	border-color: #0f6b63
}
.btn-gironina:hover{
	background-color: #0f6b63;
	border-color: #039687;
}
#sponsor{max-width: 187px;}
.modal-logo{
	margin-right: 10px;
}
/*FOOTER*/
.footer{
	background-color: #333;
	color:white;
	padding-top: 20px;
	margin-top: 20px;
}
.mail-footer{
	color:white;
}
.mail-footer:hover{
	color:#82b501;
}
.nav-up{
	position: fixed;
	display: block;
	bottom: 20px;
	right: 20px;
	width: 60px;
	height: 60px;
	background-image: url('../img/up_arrow.png');
	background-repeat: no-repeat;
}
.checkbox-right {
    float: right;
    margin-right: 20px;
}
/*Content*/
.content > h3, .content div > h2{
		font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    padding: 10px 0px 5px 0px;
    clear: both;
    font-size: 1.6em;
    color:#586b79;
    border-bottom: 2px solid #039687;
}
/*Left sidebar*/
#sidebar .module-title {
    border-bottom: 1px solid #cfd3cf;
    border-top: 1px solid #fff;
    color: #555;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 150%;
    margin: 0 0 5px;
    padding: 0 0 5px 10px;
    font-variant: small-caps;
    background: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  }
  #sidebar .module-body .soci, .form-button{
  	background:#039687;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px;
    text-indent: 0;
    border: 1px solid #02473f;
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    font-variant: small-caps;
    text-align: center;
    background-color: #039687;
    padding: 10px 0px;
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
}
#sidebar .module-body .soci:hover, .form-button:hover{
	background: #0f6b63;
}
a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}
.banner-logo{
	margin: 0 auto;
	border: 1px solid grey;
	margin-bottom: 10px;
}
.sidebar-title{
	margin-top: 20px;
}
.module-title{
	text-align: center;
}
/*Calendari*/
.event{
	clear: both;
	margin-bottom:4px; 
	height: 80px;
}
.calendar{
	float: left;
	max-height: 80px !important;
}
.activity { 
	border: 1px solid #ccc;
	padding: 0px 0px;
	margin: 5px 0px;
	height: 80px;
}
.activity h4{ 
	padding: 0px 10px 0px 2px;
	text-align: right;
	line-height: 17px;
	height: 48px;
}
time.icon{
  font-size: 0.8em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  margin: 0em auto;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}
time.icon *{
  display: block;
  width: 100%;
  font-size: 1.2em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  font-variant: small-caps;
}
time.icon strong{
  position: absolute;
  top: 0;
  padding: 0.2em 0;
  color: #bbb;
  background-color: #039687;
  box-shadow: 0 2px 0 #039687;
}
time.icon em{
  position: absolute;
  bottom: 0.2em;
  color: #039687;
}
time.icon span{
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.80em;
  color: #2f2f2f;
}

time.icon:hover, time.icon:focus{
  -webkit-animation: swing 0.6s ease-out;
  animation: swing 0.6s ease-out;
}
@-webkit-keyframes swing {
  0%   { -webkit-transform: rotate(0deg)  skewY(0deg); }
  20%  { -webkit-transform: rotate(12deg) skewY(4deg); }
  60%  { -webkit-transform: rotate(-9deg) skewY(-3deg); }
  80%  { -webkit-transform: rotate(6deg)  skewY(-2deg); }
  100% { -webkit-transform: rotate(0deg)  skewY(0deg); }
}

@keyframes swing {
  0%   { transform: rotate(0deg)  skewY(0deg); }
  20%  { transform: rotate(12deg) skewY(4deg); }
  60%  { transform: rotate(-9deg) skewY(-3deg); }
  80%  { transform: rotate(6deg)  skewY(-2deg); }
  100% { transform: rotate(0deg)  skewY(0deg); }
}
/*Newsletter form*/
.newsletter-box{border: 2px solid #039687;background-color: #DCF7EE; padding: 15px 20px 10px 10px;height: 320px;}
/* Custom, iPhone Retina */ 
		 {

		}
    @media only screen and (min-width : 200px){
      .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover{
				background-color: #104A45;
			}
			.navbar-brand{
				padding: 0;
				margin-left: 20%;
				padding-top: 7px;
			}
			.logo img{
				height: 40px;
			}
			.logo{
				margin: 0 auto;
			}
			.slogan-smallest{
				display: all;
				color: white;
				margin-left: 10px;
			}
			.slogan-small{
				display: none;
			}
			.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
			    color: white;
			    background-color: #104A45	;
			}
			.navbar-inverse .navbar-nav .open .dropdown-menu > li > a{
				color: white;
			}
			.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
			    color: #82b501;
			    background-color: transparent;
			}
			.contact-bottom{
				text-align: center;
				padding-bottom: 20px;
				border-bottom: 1px solid #888
			}
			.container-slider, .container-slider .row{
				margin-top: 100px;
			}
			.callbacks .caption{
				font-size: 20px;
				top: 0%;
				line-height: 50px;
			}
			.callbacks .caption a{
				font-size: 15px;
			}
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {

			.navbar-brand{
				padding: 0;
				margin-left: 20%;
				padding-top: 7px;
			}
			.slogan-smallest{
				display: none;
			}
			.slogan-small{
				display: inline;
				padding-left: 10px;
				color: white;
			}
			.container-slider, .container-slider .row{
				margin-top: 120px;
			}
			.callbacks .caption{
				font-size: 25px;
				top: 10%;
				line-height: 60px;
			}
			.callbacks .caption a{
				font-size: 20px;
			}
    }
     @media only screen and (min-width : 600px) {
			.container-slider, .container-slider .row{
				margin-top: 130px;
			}
		}
     @media only screen and (min-width : 700px) {
			.container-slider, .container-slider .row{
				margin-top: 140px;
			}
		}
    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
    	.navbar-nav{
    		margin-left: 20px
    	}
			#navbar-main{
				height: 90px;
			}
			.slogan-small, .slogan-smallest{
				display: none;
			}
			.logo{
				float: left;
			}
			.logo img{
				height: 80px;
				margin-right: 10px;
			}
			.slogan{
				float: right;
				margin-top: 8px;
			}
			.nav{
				margin-top:20px;
			}
			.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
			    color: white;
			    background-color: #104A45;
			}
			.navbar-inverse .navbar-nav .open .dropdown-menu > li > a{
				color: black;
			}
			.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover{
				color: white;
				background: #555;
			}
			.nav > li > a{
				padding: 15px 10px;
			}
			#navbar-main .container{
				width: 100%;
			}
			.logo img{
				width: 60px;
				height: 60px;
				margin-top: 8px;
			}
			.slogan{
				font-size:0.8em;
			}
			.anel{
				display: none;
			}
			.contact-bottom{
				padding-top: 20px;
				padding-right: 40px;
				text-align: right;
				border-bottom: none;
			}
			.callbacks .caption{
				font-size: 45px;
				top: 20%;
				line-height: 80px;
			}
			.callbacks .caption a{
				font-size: 25px;
			}
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
    	ul.nav li.dropdown:hover > ul.dropdown-menu {
		    display: block;
		  }
		  .anel{
		  	display: inline;
		  }
		  .navbar-inverse .navbar-nav > li > a{
				color: white;
				font-size: 1.4em;
			}
			.navbar-right li a{
				font-size: 1em !important;
				padding-right: 0 !important;
				padding-left: 0 !important;
			}
			.nav > li > a{
				padding: 15px 15px;
			}
			.container-slider{
				width: 100%
			}
			.container-2{
				margin-left:0;
			}
			.container-3{
				margin-left: 0;
			}
			#navbar-main .container{
				width: 90%;
			}
			.logo img{
				width: 80px;
				height: 80px;
				margin-right: 10px;
				margin-top: 0px;
			}
			.slogan{
				float: right;
				margin-top: 8px;
				font-size: 1em;
			}	
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
    	.container-1, .container-2, .container-3{
				margin-top: -250px; 
				z-index: 1000;
				border: none;
			}
			.container-2{
				margin-left: 33%;
			}
			.container-3{
				margin-left: 66%;
			}
    }
